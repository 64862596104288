import React from "react";
import SongModalLayout from "../../common/SongModalLayout/SongModalLayout";

import cls from "./SignInModal.module.scss";

const SignInModal = ({
  show = false,
  onClose = () => { },
  onCreateAccount = () => { },
  onAlreadyHaveAccount = () => { }
}) => {
  if (!show) return null;

  return (
    <SongModalLayout
      onClose={onClose}
      title="sign in"
    >
      <p className={cls.signInText}>Sign in to continue</p>
      <div className={cls.signInModalContent}>

        <button
          className={`btn ${cls.createAccountBtn}`}
          onClick={() => { onAlreadyHaveAccount() }}
        >Continue</button>

        <button className={cls.alreadyHaveAccountBtn} onClick={() => { onCreateAccount()  }}>
          <p className={cls.signInText}>Create a SongBits Account</p>
        </button>
      </div>
    </SongModalLayout>
  );
};

export default SignInModal;