import React, { useEffect, useState } from "react";

// library
import { BrowserRouter, Routes, Route } from "react-router-dom";

// components
//import { Home } from "../../pages/Home/Home";
//import Login from "../../pages/Login/Login";
//import { JoinWaitList } from "../../pages/JoinWaitList/JoinWaitList";
//import { JoinWaitListThanks } from "../../pages/JoinWaitListThanks/JoinWaitListThanks";
import { BeforeReleaseDate } from "../../pages/BeforeReleaseDate/BeforeReleaseDate";
import { BeforeReleaseDateAfter } from "../../pages/BeforeReleaseDate/BeforeReleaseDateAfter";
//import { JoinWaitListApprove } from "../../pages/JoinWaitListApprove/JoinWaitListApprove";
//import ForgotPassword from "../../pages/ForgotPassword/ForgotPassword";

import ToasterContainer from "../Toaster/Toaster";
import { routes } from "../../../utils/routes";
import { LogoLoader } from "../LogoLoader/LogoLoader";


function lastSunday(month, year) {
  const d = new Date();
  const lastDayOfMonth = new Date(Date.UTC(year || d.getFullYear(), month+1, 0));
  const day = lastDayOfMonth.getDay();
  return new Date(Date.UTC(lastDayOfMonth.getFullYear(), lastDayOfMonth.getMonth(), lastDayOfMonth.getDate() - day));
}

function isBST(date) {
  const d = date || new Date();
  const starts = lastSunday(2, d.getFullYear());
  starts.setHours(1);
  const ends = lastSunday(9, d.getFullYear());
  starts.setHours(1);
  return d.getTime() >= starts.getTime() && d.getTime() < ends.getTime();
}


export function checkLive(date_string) {

  const str_date = date_string

  const year = parseInt(str_date.substring(0, 4));
  const month = parseInt(str_date.substring(5, 7)) - 1;
  const day = parseInt(str_date.substring(8, 10));
  
  let hour = parseInt(str_date.substring(11, 13));
  //const hour = parseInt(str_date.substring(11, 13));
  const minute = parseInt(str_date.substring(14, 16));
  const second = parseInt(str_date.substring(17, 19));

  const date_ = new Date(Date.UTC(year, month, day, hour, minute, second))

  if (isBST(date_)) {
    hour -= 1
  }

  const date = new Date(Date.UTC(year, month, day, hour, minute, second)).getTime()

  const now = Date.now()

  return {
    date_epoch: date,
    now_epoch: now
  }

}

export const App = () => {
  const [loading, setLoading] = useState(true);

  const str_date = process.env.REACT_APP_RELEASE_AT;

  const is_live = checkLive(str_date);

  const date_check = is_live.now_epoch > is_live.date_epoch

  const onPageLoad = () => {
    setTimeout(() => {
      setLoading(false)
    }, 500)
  };

  useEffect(() => {
    // Check if the page has already loaded
    if (document.readyState === 'complete') {
      onPageLoad();
    } else {
      window.addEventListener('load', onPageLoad, false);
      // Remove the event listener when component unmounts
      return () => window.removeEventListener('load', onPageLoad);
    }
  }, []);

  return (
    <>
      {loading && <LogoLoader />}
      <BrowserRouter>
        <Routes> 
            <>
              {date_check ? (
                <Route path={routes.home} element={<BeforeReleaseDateAfter />}>
                  <Route index element={<BeforeReleaseDateAfter />} />
                </Route>
              ) : (
                <Route path={routes.home} element={<BeforeReleaseDate />}>
                  <Route index element={<BeforeReleaseDate />} />
                </Route>
              )}
            </>
        </Routes>
        <ToasterContainer />
      </BrowserRouter>
    </>
  );
};
