import React from "react";

import cls from "./SongModalLayout.module.scss";

const SongModalLayout = (
  {
    children,
    onClose = () => {},
    title = "",
    footerText = "",
    className
  }) => {
  return (
    <div className={`${cls.wrapper} ${className}`}>
      <div className={`${cls.modalContent} modalContent`}>
        <div className={`${cls.header} header`}>
          <p className={cls.title} dangerouslySetInnerHTML={{ __html: title }} />
          <button className={`${cls.closeBtn} closeBtn`} onClick={onClose}>
            X
          </button>
        </div>
        <div className={`${cls.content} content`}>
          {children}
        </div>
        <div className={cls.footer}>
          <p className={cls.footerText}>{footerText}</p>
        </div>
      </div>
    </div>
  );
};

export default SongModalLayout;
