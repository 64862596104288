import React from "react";

// components
import SongModalLayout from "../../common/SongModalLayout/SongModalLayout";

// assets
import styles from './CheckInboxModal.module.scss';

const CheckInboxModal = ({ show = false, onClose }) => {
  if (!show) return null;

  return (
    <SongModalLayout
      title="check your <br/> inbox!"
      footerText=""
      onClose={onClose}
      className={styles.checkInboxModal}
    >
      <p>We’ll be in touch closer to the release day</p>
      <button type='button' className="btn btn-yellow-outlined" onClick={onClose}>Close</button>
    </SongModalLayout>
  );
};

export default CheckInboxModal;
