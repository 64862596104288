import React, { useState } from 'react';

// components
import { VideoModal } from "../../modals";
import { Benefits, Countdown, Layout } from "../../common";
import { useWindowSize } from "../../../utils/useWindowSize";

import SignInModal from "../../modals/SignInModal/SignInModal";
// assets
import styles from "./BeforeReleaseDate.module.scss";
import playBtn from "../../../images/icons/play.svg";
import background from "../../../images/ashley-wallbridge-banner.jpg";
import backgroundMobile from "../../../images/ashley-wallbridge-banner-mobile.jpg";
import user from "../../../images/icons/user.svg";
import mp3 from "../../../images/icons/mp3.svg";
import money from "../../../images/icons/money.svg";
import ticket from "../../../images/icons/ticket.svg";
import stream from "../../../images/icons/video.svg";
import signed from "../../../images/icons/signed.svg";
import history from "../../../images/icons/content.svg";
import buyNow from "../../../images/icons/buy-now.svg";
//import hear from "../../../images/icons/hear.svg";
import ashley from "../../../images/ashley.png";
import dna from "../../../images/dna.png";
import join from "../../../images/icons/join.svg";



function lastSunday(month, year) {
  const d = new Date();
  const lastDayOfMonth = new Date(Date.UTC(year || d.getFullYear(), month+1, 0));
  const day = lastDayOfMonth.getDay();
  return new Date(Date.UTC(lastDayOfMonth.getFullYear(), lastDayOfMonth.getMonth(), lastDayOfMonth.getDate() - day));
}

function isBST(date) {
  const d = date || new Date();
  const starts = lastSunday(2, d.getFullYear());
  starts.setHours(1);
  const ends = lastSunday(9, d.getFullYear());
  starts.setHours(1);
  return d.getTime() >= starts.getTime() && d.getTime() < ends.getTime();
}

export function checkLive(date_string) {

  const str_date = date_string

  const year = parseInt(str_date.substring(0, 4));
  const month = parseInt(str_date.substring(5, 7)) - 1;
  const day = parseInt(str_date.substring(8, 10));
  
  let hour = parseInt(str_date.substring(11, 13));
  //const hour = parseInt(str_date.substring(11, 13));
  const minute = parseInt(str_date.substring(14, 16));
  const second = parseInt(str_date.substring(17, 19));

  const date_ = new Date(Date.UTC(year, month, day, hour, minute, second))

  if (isBST(date_)) {
    hour -= 1
  }

  const date = new Date(Date.UTC(year, month, day, hour, minute, second)).getTime()

  const now = Date.now()

  return {
    date_epoch: date,
    now_epoch: now
  }

}

export const BeforeReleaseDateAfter = () => {
  const { width } = useWindowSize();

  const [showSignInModal, setShowSignInModal] = useState(false);

  const benefits = [];

  if (process.env.REACT_APP_PERK_ROYALTY) {
    benefits.push({
      title:
        "A share of the streaming revenues from ‘" +
        process.env.REACT_APP_SONG_NAME +
        "’ forever!",
      icon: money,
    });
  }

  benefits.push({ title: 'A personalized video message from Ashley & Bodine for the first 100 buyers', icon: stream })

  if (process.env.REACT_APP_PERK_NFT_IMAGE) {
    benefits.push({
      title: "A unique numbered exclusive image just for you",
      icon: user,
    });
  }

  benefits.push({ title: 'Exclusive access to Ashley’s unreleased music and historical tracks', icon: history })

  if (process.env.REACT_APP_PERK_TICKET) {
    benefits.push({ title: process.env.REACT_APP_PERK_TICKET, icon: ticket });
  }

  if (process.env.REACT_APP_PERK_DOWNLOAD) {
    benefits.push({ title: "An MP3 download of the song to keep", icon: mp3 });
  }

  benefits.push({ title: 'Video Hangout With Ash & Bodine for the top 20 buyers.', icon: stream })

  benefits.push({ title: 'Signed Ashley Wallbridge exclusive album for the first 20 buyers', icon: signed })

  const [showVideo, setShowVideo] = useState(false);

  const artist_name = process.env.REACT_APP_ARTIST_NAME;
  const song_name = process.env.REACT_APP_SONG_NAME;

  const title =
    process.env.REACT_APP_SONG_NAME +
    " - " +
    process.env.REACT_APP_ARTIST_NAME +
    " on SongBits";
  const video_url = process.env.REACT_APP_VIDEO_URL;

  const str_date = process.env.REACT_APP_RELEASE_AT;


  const is_live = checkLive(str_date);

  const CountDown = () => (
    <div className={styles.countdown}>
      {video_url && (
        <div className={styles.play} onClick={() => setShowVideo(true)}>
          <img src={playBtn} alt="" />
        </div>
      )}
      <p className={styles.yellow}>COUNTDOWN TO SALE </p>
      <div className={styles.timer}>
        <Countdown time={is_live.date_epoch} />
      </div>
    </div>
  )

  const Join = () => (
    <div className={styles.released}>
      <h3>
        <img src={join} alt='' />
        Release Live Now!
      </h3>


          <button type="submit" onClick={() => {
                    setShowSignInModal(true)
                  }} ><img src={buyNow} alt='' /></button>

      
    </div>
  )

  return (
    <Layout
      className={styles.beforeRelease}
      title={title}
      artistName={process.env.REACT_APP_ARTIST_NAME}
      songName={process.env.REACT_APP_SONG_NAME}
      background={width > 567 ? background : backgroundMobile}
      noLogo
    >
      {showVideo && <VideoModal setShowVideo={setShowVideo} src={video_url} />}
      <div className={styles.kayoralpha}>
        <div className={styles.title}>
          <h1>{artist_name.toLocaleUpperCase()}</h1>
          <p className={styles.drop}>‘{song_name}’</p>
          <div className={styles.black}>
            <p>ownership release</p>
          </div>
        </div>
        <div className={styles.content}>
          <div className={styles.left}>
            {width <= 768 && <CountDown />}
            <div className={styles.panel}>
              <h4>what is this?</h4>
              <p>
                <span>This is the future, Baby!</span> Radio, Vinyl, CDs or Streaming music is all a bit...well boring
                (ok
                Vinyl is still cool!). But what if I told you that you could not only just listen to but actually <span>own a bit of my latest release - ‘master of’ for just $1!</span>
              </p>
              {width <= 768 && <Join />}
              <h4>what does ownership actually mean?</h4>
              <p>
                Unlike simply listening to the song, fans who buy a bit will share in the streaming royalties (money) it
                makes every time it’s played on Spotify, Apple Music, Amazon, YouTube and more, worldwide, forever! How
                cool is that?
              </p>
              <h4>hold on - there’s more!</h4>
              <p>
                As a a ‘VIP’ fan you’ll get to download and listen to the track before anyone else and be the envy of
                other fans. You'll also get some pretty impressive bonus goodies thrown in as a thanks to fans who
                purchase a bit of the song.
              </p>
              <p>
                Let’s get this party started!
              </p>
            </div>
          </div>
          <div className={styles.right}>
            {width > 768 && <Join />}
            {width > 768 && <CountDown />}
            <div className={styles.panel}>
              <h4>here’s whats included</h4>
              <Benefits benefits={benefits} />
              {width <= 768 && <Join />}
            </div>
          </div>
        </div>
        <div className={styles.association}>
          <div className={styles.logos}>
            <p className={styles.upper}>IN ASSOCIATION WITH</p>
            <img src={ashley} alt='' />
            <img src={dna} alt='' />
            <p className={styles.upper}>DNA GLOBAL AGENCY</p>
          </div>
          <p>
            You can opt out of email communication at any time. <br />
            *depending on date of purchase, the time remaining before the song is publicly available may vary. Terms and
            conditions apply.
          </p>
        </div>
      </div>

      <SignInModal
          show={showSignInModal}
          onClose={() => setShowSignInModal(false)}
          onCreateAccount={() => {
            window.location.href = process.env.REACT_APP_SITE_URL + '/create-account?artist=' + process.env.REACT_APP_ARTIST_ID
          }}
          onAlreadyHaveAccount={() => {
            window.location.href = process.env.REACT_APP_SITE_URL + process.env.REACT_APP_SONG_URL
          }}
        />
    </Layout>
  );
};